import React from 'react';
import logo from './logo.png';
import axios from 'axios';
import {ListGroup} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import spinner from './spinner.gif'

const logoStyle = {
  margin: '10px'
}

const spacing = {
  margin: '20px'
}


 class StudentDetails extends React.Component {
   state = {
     students: [],
     daycareStudents: [],
     beginnerStudents: [],
     pp1Students: [],
     pp2Students: [],
     grade1Students: [],
     grade2Students: [],
     grade3Students: [],
     isLoaded: false,
     selectedStudents: [],
     classroom: localStorage.getItem('class')
   }

   componentDidMount(){
     axios.get('https://blooms-mis.herokuapp.com/api/students')
     .then( res => {
       this.setState({
         students: res.data,
         isLoaded: true,
         daycareStudents: res.data.filter( student => student.class.toLowerCase() === 'daycare'),
         beginnerStudents: res.data.filter( student => student.class.toLowerCase() === 'beginner'),
         pp1Students: res.data.filter( student => student.class.toLowerCase() === 'pp1'),
         pp2Students: res.data.filter( student => student.class.toLowerCase() === 'pp2'),
         grade1Students: res.data.filter( student => student.class.toLowerCase() === 'grade1'),
         grade2Students: res.data.filter( student => student.class.toLowerCase() === 'grade2'),
         grade3Students: res.data.filter( student => student.class.toLowerCase() === 'grade3'),
       })
     }).catch(err => console.log(err))

   }

   getStudents = () =>{
    switch(localStorage.getItem('class')) {
        case 'daycare':
            return this.state.daycareStudents
            
        case 'beginner':
            
                return this.state.beginnerStudents
                       
        case 'pp1':
            return this.state.pp1Students

        case 'pp2':
            return this.state.pp2Students
        case 'grade1':
            return this.state.grade1Students
        case 'grade2':
            return this.state.grade2Students
        case 'grade3':
            return this.state.grade3Students
            
        default:
            console.log('error')    
     }
   }
   render(){
     if(this.state.isLoaded) {  
      return (
        <div style={{width: '100%', height: '100%'}}>
    
         <img src={logo} alt="school logo" style={logoStyle}/>
        <div style={{
            position: 'absolute',
          width: '90%',
          top: '27.5%'
        }}
        >
        
        <div style={{
          position: 'absolute',
          bottom: '90%',
          width: '100%',
          height: '5rem'
        }}>
          <p style={{color: 'green', fontSize: '18px', fontWeight: 'bold', paddingLeft: '15px', marginTop: '20px'}}>Please select your child's name to update their details</p>
          <p style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '15px', position: 'absolute', top: '100%', left: '5px'}}>{localStorage.getItem('class').toUpperCase()}</p>
        </div>
        
          <ListGroup style={{
            position: 'absolute',
            top: '30px',
            width: '100%'
          }}>
            {
                this.getStudents().map( student => {
                    return(
                        <Link to={"/update-details"} key={student._id} onClick={
                            () => {
                                localStorage.setItem('id', student._id)
                            }
                        }>
                        <ListGroup.Item variant="secondary" style={spacing}>{student.firstName} {student.middleName} {student.lastName}</ListGroup.Item>
                        </Link>
                    )
                })
            }   
  
           
          </ListGroup>
        </div>
             
         </div>
      )
     }

     return (
     <div style={{
         width: '100%',
         height: '100%',
         display: 'flex'
     }}>
       <img src={spinner} alt="spinning fetching..." style={{margin: 'auto', width: '80px', height: '80px', position: 'absolute', top: '35%', left: '35%'}}/>
     </div>
     )
  
   }

}

export default StudentDetails;
