import React from 'react';
import logo from './logo.png';
import axios from 'axios';
import {Form, Button} from 'react-bootstrap';
import spinner from './spinner.gif';


const logoStyle = {
  margin: '10px'
}


 class UpdateStudentDetails extends React.Component {
     state={
         student: {},
         isLoaded: false
     }

     componentDidMount() {
        axios.get(`https://blooms-mis.herokuapp.com/api/students/${localStorage.getItem('id')}`).then( res => {
            this.setState({
                isLoaded: true,
                student: res.data
            })
            console.log(this.state.student)
        })
     }

     getRoutes = (route) => {
        let routesArray = [];
        routesArray = window.location.href.split("/");

        
        window.location.href = routesArray.join('/');
     }

 
   render(){
       if(this.state.isLoaded) {
        return (
          <div style={{width: '100%', height: '100%'}}>
      
           <img src={logo} alt="school logo" style={logoStyle}/>
          <div style={{
              position: 'absolute',
            width: '100%',
            top: '22.5%'
          }}
          >
          
          <div style={{
            position: 'absolute',
            bottom: '100%',
            width: '100%'
          }}>
            <p style={{color: '#000', fontSize: '16px', fontWeight: 'bold', marginLeft: '15px', position:'absolute', top: '-15px'}}>Update {this.state.student.firstName} {this.state.student.middleName} {this.state.student.lastName} details</p>
          </div>
          
           
          </div>

           <Form style={{
               position: 'absolute',
               top: '25%',
               width: '90%',
               left: '5%'
           }}>
            <Form.Group controlId="formBasicFName">
                <Form.Label>Father's Name</Form.Label>
                <Form.Control name="details" type="textbox" placeholder="Full name" />
            </Form.Group>

            <Form.Group controlId="formBasicFNumber">
                <Form.Label>Father's Mobile</Form.Label>
                <Form.Control name="details" type="textbox" placeholder="e.g 07********" />
            </Form.Group>

            <Form.Group controlId="formBasicFEmail">
                <Form.Label>Father's Email Address</Form.Label>
                <Form.Control name="details" type="email" placeholder="e.g example@example.com" />
            </Form.Group>

            <Form.Group controlId="formBasicMName">
                <Form.Label>Mother's Name</Form.Label>
                <Form.Control name="details" type="textbox" placeholder="Full name" />
            </Form.Group>

            <Form.Group controlId="formBasicMNumber">
                <Form.Label>Mother's Mobile Number</Form.Label>
                <Form.Control name="details" type="textbox" placeholder="e.g 07********" />
            </Form.Group>

            <Form.Group controlId="formBasicMEmail">
                <Form.Label>Mother's Email Address</Form.Label>
                <Form.Control name="details" type="email" placeholder="e.g example@example.com" />
            </Form.Group>

            <Form.Group controlId="formBasicGName">
                <Form.Label>Guardian's Name</Form.Label>
                <Form.Control name="details" type="textbox" placeholder="Full name" />
            </Form.Group>

            <Form.Group controlId="formBasicGNumber">
                <Form.Label>Guardian's Mobile Number</Form.Label>
                <Form.Control name="details" type="textbox" placeholder="e.g 07********" />
            </Form.Group>

            <Form.Group controlId="formBasicGNumber">
                <Form.Label>Guardian's Email Adress</Form.Label>
                <Form.Control name="details" type="email" placeholder="e.g example@example.com" required={true}/>
            </Form.Group>
            
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="I acknowledge that this information is correct and to the best of my knowledge." />
            </Form.Group>
            <Button variant="primary" onClick={(e) => {
                e.preventDefault();
                let updates = {
                  studentsId: localStorage.getItem('id'),  
                  fatherName: document.getElementsByName("details")[0].value,
                  fatherContact: document.getElementsByName("details")[1].value, 
                  atherEmail: document.getElementsByName("details")[2].value,
                  motherName: document.getElementsByName("details")[3].value,
                  motherContact: document.getElementsByName("details")[4].value,
                  motherEmail: document.getElementsByName("details")[5].value,
                  guardianName: document.getElementsByName("details")[6].value,
                  guardianNumber: document.getElementsByName("details")[7].value,
                  guardianEmail: document.getElementsByName("details")[8].value
                }

                window.prompt("Submit updated details?(yes)")
                axios.post('https://blooms-mis.herokuapp.com/api/update/student/details', updates).then( res => {
                console.log(res)
                this.getRoutes("");
                }).catch(err => console.log(err))
            }}>
                Submit
            </Button>
            </Form>    
           </div>
        )
  
       }
      return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex'
        }}>
          <img src={spinner} alt="spinning fetching..." style={{margin: 'auto', width: '80px', height: '80px', position: 'absolute', top: '35%', left: '35%'}}/>
        </div>
        );
}
 }

export default UpdateStudentDetails;
