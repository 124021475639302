import React, { useState, useEffect } from 'react';
import logo from '../logo.png';
import axios from 'axios';
import {ListGroup} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import spinner from '../spinner.gif'

const RegisteredList = () => {
    const [students, getStudents] = useState([]);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        axios.get('https://obscure-hamlet-14413.herokuapp.com/get_students')
          .then(res => {
            getStudents([...new Set(res.data)]);
            console.log('Got students registered.')
        }).catch(err => console.log(err))
    },
    []
    )
    return(
        <div>
            <h3 style={{
                padding: '25px'
            }}>
                Students who have registered for virtual learning. ({students.length})
                </h3>
            {
                students.map(student => {
                    return(
                        <div key={student._id} style={{
                            paddingLeft: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                            border: 'solid 1px red'
                          }}>
                            <p><b>Parent Name:</b>{student.parentName} </p>
                            <p><b>Student Name:</b>{student.childsName} </p>
                            <p><b>Class level:</b>{student.classLevel} </p>
                            <p><b>Gender:</b>{student.gender} </p>
                            <p><b>Parent Email:</b>{student.parentEmail} </p>
                            <p><b>Parent Phone Number:</b>{student.phoneNumber} </p>
                        </div>
                    )
                })
            }
       
        </div>
    )
};

export default RegisteredList;