import React from 'react';
import logo from '../logo.png';

import spinner from '../spinner.gif'

const logoStyle = {
  margin: '10px'
}




 class Thankyou extends React.Component {
   state = {
     isLoaded: false
       }

   componentDidMount(){
       this.setState({
         isLoaded: true
       })
    
   }


   render(){
     if(this.state.isLoaded) {  

      return (
        <div style={{width: '100%', height: '100%'}}>
    
         <img src={logo} alt="school logo" style={logoStyle} />
        <div style={{
            position: 'absolute',
          width: '90%',
          top: '27.5%'
        }}
        >
        
        <div style={{
          position: 'absolute',
          bottom: '90%',
          width: '100%',
          height: '5rem'
        }}><br /><br /><br />
          <p style={{color: 'green', fontSize: '24px', fontWeight: 'bold', paddingLeft: '15px', marginTop: '20px', width: '100%', textAlign: 'center'}}>
            Thank you for registering to Bloomsfield School Digital Learning Platforms
            <br />
            Kindly check your phone for a confirmed message.
            </p>
       

        </div>
      
          
        </div>
             
         </div>
      )
     }

     return (
     <div style={{
         width: '100%',
         height: '100%',
         display: 'flex'
     }}>
       <img src={spinner} alt="spinning fetching..." style={{margin: 'auto', width: '80px', height: '80px', position: 'absolute', top: '35%', left: '35%'}}/>
     </div>
     )
  
   }

}

export default Thankyou;
