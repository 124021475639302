import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import StudentDetails from './StudentDetails';
import UpdateStudentDetails from './UpdateStudentDetails';
import Enrollment from './forms/Enrollment';
import Thankyou from './forms/Thankyou';
import RegisteredList from './lists/RegisteredList';

const App = () =>{
    
  return(

<BrowserRouter>
  <Switch>
    <Route exact path={"/"} component={Enrollment}></Route>
    <Route  path={"/details"} component={StudentDetails}></Route>
    <Route  path={"/update-details"} component={UpdateStudentDetails}></Route>  
    <Route  path={"/thank-you"} component={Thankyou}></Route>  
    <Route path={"/registered-students"} component={RegisteredList}></Route>
  </Switch>

</BrowserRouter>)
}
export default App;
