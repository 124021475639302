import React from 'react';
import logo from '../logo.png';
import {Modal,Spinner, Form, Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';

import spinner from '../spinner.gif'
import axios from 'axios';

const logoStyle = {
  margin: '10px'
}

class Enrollment extends React.Component {
   state = {
        isLoaded: false,
        submitted: false,
        errorText: 'none',
        modalDisplay: 'none',
        payments: [],
        mpesaText: '',
        phone: '',
        form: {}
       }

   componentDidMount(){
    this.setState({
      isLoaded: true
    })
   }

   handleSubmit = () => {
    this.setState({
      submitted: true
    })
   }

  //  formValidation = async () => {
  //     const fields =  await Array.from(document.getElementsByClassName("form-fields")).map(field => field);

  //   const formDetails = {
  //     parentName: fields[0].value,
  //     childsName: fields[1].value,
  //     classLevel: fields[2].value,
  //     gender: fields[3].value,
  //     parentEmail: fields[4].value,
  //     phoneNumber: fields[5].value
  //   }

  //   console.log(formDetails);
  //   return formDetails;
  //  };

  toggleModal = () => {
     this.state.modalDisplay === 'none' ? this.setState({ modalDisplay: 'flex'}) : this.setState({ modalDisplay: 'none'})
   };


  determineAmount = (classLevel) => {
    switch(classLevel){
      case 'Beginner':
        return "4000"

      case 'PP1':
        return "4500"
        
      case 'PP2':
        return "4500"
        
      case 'Grade 1':
        return "4500"
        
      case "Grade 2":
        return "4500"
        
      case "Grade 3":
        return "4500"
        
      default:
        return 0  
    }
  };

  sendMessage = async (phoneNumber) => {
    try{

      console.log('sendMessage function called');
      console.log(phoneNumber);
      
      axios.post('https://obscure-hamlet-14413.herokuapp.com/sendMessage', { phoneNumber: phoneNumber})
      .then(res => {
        console.log('message sent!  ' + res);
      })
      .catch(err => console.log(err));
    
    } catch (err) { console.log(err) }
 
  };

   mpesaConfirmation = async (enteredNumber) => {
     try{
      axios.get('https://obscure-hamlet-14413.herokuapp.com/get_stk').then(res => {
        if(enteredNumber === res.data.filter( data => data.phoneNumber === enteredNumber).map(data => data.phoneNumber)[0]){
          console.log('confirmed succesful payment.');
          this.saveStudent(this.state.form)
          this.sendMessage(`+${this.state.phone}`);
          this.props.history.push('/thank-you');
          return 0;
        }
        alert('Error in transaction. Ensure you have sufficient funds in MPESA and have entered a valid number.');
        window.location.reload();
        console.log('no match unfortunately.');
      }).catch( err => {
        console.log(err);
      });
     } catch (err) { console.log(err) }
    
   };



   saveStudent = (values) => {
     axios.post('https://obscure-hamlet-14413.herokuapp.com/saveStudent', values).then(res => {
        console.log('succesfully added student ' + res);
     }).catch(err => {
       console.log(err);
     })
    } 

  stkPush = async () => {
      const fields =  await Array.from(document.getElementsByClassName("form-fields")).map(field => field);
        
      const emptyFields = fields.filter(field => field.value.length < 1);
     if(emptyFields.length > 1){
  
      emptyFields.forEach(field => {
        field.style.border = "solid 1px red"
      })
      this.setState({
        submitted: false,
        errorText: 'block'
      });
  
      return 0;
     }
  
     let phoneNum = fields[5].value.split('');
  
     phoneNum.splice(0,1,'254');
  
     let newNum = phoneNum.join('');
  
     const formDetails = {
        parentName: fields[0].value,
        childsName: fields[1].value,
        classLevel: fields[2].value,
        gender: fields[3].value,
        parentEmail: fields[4].value,
        phoneNumber: fields[5].value
      }
  
      this.setState({
        phone: newNum,
        form: formDetails
      });
  
      axios.post('https://obscure-hamlet-14413.herokuapp.com/stk', { phoneNumber: newNum, amount: this.determineAmount(fields[2].value)  })
       .then(res => {
        console.log('success ' + res);
        setTimeout( () => {this.mpesaConfirmation(newNum)}, 10000);   
       }).catch(err => console.log(err));
      };

render(){
  if(this.state.isLoaded) {  
    const loadSubmitBtn =  <Button variant="success" disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Please wait...
                            </Button>;
                              
    const submitBtn = <Button variant="success" type="submit" onClick={
                        async (e) => {
                          e.preventDefault();
                          this.handleSubmit();
                          this.stkPush();
                            }
                          }>
                          Register my Child
                      </Button>                        

  return (
    <div style={{width: '100%', height: '100%'}}>
      <div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.8)', display: this.state.modalDisplay, zIndex: 12, position: 'fixed'}}>
        <Modal.Dialog
         style={{ 
          margin: 'auto',
          zIndex: 13, 
          width: '80%', 
          height: '70%'}}>
        <Modal.Header>
          <Modal.Title>Payment Confirmation</Modal.Title>
        </Modal.Header>

      <Modal.Body>
        <p>Kindly check your phone and enter the MPESA reference code from the confirmation message from MPESA.</p>
        <Form.Label>MPESA Reference Code</Form.Label>
        <Form.Control className="form-mpesa" type="text" placeholder="OHQ0YD4RSE" onChange={
          (value) => {
            this.setState({
              mpesaText: value.target.value
            })
        }} />
        
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => {
          console.log(this.state.mpesaText);
              }}>
          Confirm Payment
          </Button>
      </Modal.Footer>
    </Modal.Dialog>
    </div>
         <img src={logo} alt="school logo" style={logoStyle}/>
        <div style={{
            position: 'absolute',
          width: '90%',
          top: '27.5%'
        }}
        >
        
        <div style={{
          position: 'absolute',
          bottom: '90%',
          width: '100%',
          height: '5rem'
        }}>
          <p style={{color: 'green', fontSize: '24px', fontWeight: 'bold', paddingLeft: '15px', marginTop: '20px'}}>
            Digital Learning Registration
            </p>
          <p style={{ padding: '15px'}}>
              
              <b>Beginner Class: Kes 4,000.00</b><br />
              <b>PP1-PP2: Kes 4,500.00</b><br />
              <b>Grade1-3: Kes 4,500.00</b><br /><br />
              <b>Payment Mode:</b>
              • Paybill MPESA<br /><br />
              <b>To register see below details</b> <br /><br />
              • Complete the e-registration Form attached below<br />
              • Click on 'Register my child' button <br />
              • You will be prompted to pay the required amount on your phone. <br />
              • A confirmation message will be sent to your number.
            
          </p>

                    <Form style={{padding: '15px'}}>
                    <Form.Group controlId="formBasicName">
                <Form.Label>Name of parent</Form.Label>
                <Form.Control className="form-fields" type="text" placeholder="Enter name" />
            </Form.Group>
            <Form.Group controlId="formBasicChild">
                <Form.Label>Child's Name</Form.Label>
                <Form.Control className="form-fields" type="text" placeholder="Enter child's name" />
            </Form.Group>

            <Form.Group controlId="formBasicChildLevel">
                <Form.Label>Child's Class Level</Form.Label>
              <Form.Control className="form-fields" as="select">
                <option>Beginner</option>
                <option>PP1</option>
                <option>PP2</option>
                <option>Grade 1</option>
                <option>Grade 2</option>
                <option>Grade 3</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicChildGender">
              <Form.Label>Child's Gender</Form.Label>
              <Form.Control className="form-fields" as="select">
                <option>Male</option>
                <option>Female</option>
              </Form.Control>
            </Form.Group>
            
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Enter Parent/Guardian Email</Form.Label>
                <Form.Control className="form-fields" type="text" placeholder="example@example.com" />
            </Form.Group>
            
            <Form.Group controlId="FormBasicNumber">
                <Form.Label>Enter Parent/Guardian M-PESA Phone Number</Form.Label>
                <Form.Control className="form-fields" type="text" placeholder="0712345678" />
            </Form.Group>
            <small style={{color: 'red', display: this.state.errorText}}>Kindly fill all fields before submitting</small>
            
            <br />
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" style={{color:'green'}} label="I hereby officially register my child for Bloomsfield School digital learning" />
            </Form.Group>
            {
              this.state.submitted ? loadSubmitBtn : submitBtn
            }
            </Form>

            <br />
        </div>
      
          
        </div>
             
         </div>
      )
     }

     return (
     <div style={{
         width: '100%',
         height: '100%',
         display: 'flex'
     }}>
       <img src={spinner} alt="spinning fetching..." style={{margin: 'auto', width: '80px', height: '80px', position: 'absolute', top: '35%', left: '35%'}}/>
     </div>
     )
  
   }

}

export default  withRouter(Enrollment);
